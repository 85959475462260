import { getXTokenF, postToken2F } from "./base.js";

// 医学公式

// 通过code获取公式
export function getFormula(type,code){
    return getXTokenF(`/user/yun/h5/medicine/formula/${type}/${code}`);
}
// 提交公式
export function postFormula(data) {
    return postToken2F(`/user/yun/h5/medicine/formula/log`, data);
}
//回显公式记录
export function getFormulaHuiXian (data) {
    return getXTokenF("/user/yun/h5/medicine/formula/log/id", data);
}

